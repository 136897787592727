window.addEventListener("DOMContentLoaded", () => {
    const dropdowns = document.querySelectorAll(".nav-item.dropdown")
    const mainOverlay = document.querySelector(".drop-overlay")
    const DELAY = 200;
    let currentTimeout = null;

    const showMenu = (dropdown, menu) => {
        menu.style.display = "block";

        dropdown.classList.add("hovered");

        const overlay = dropdown.querySelector(".drop-overlay");

        if (!overlay) return

        overlay.style.display = "block";

        overlay.addEventListener("mouseenter", hideMenusAndOverlays);
    };

    const hideMenusAndOverlays = () => {
        dropdowns.forEach((dropdown) => {
            dropdown.querySelector(".dropdown-menu").style.display = "none";

            const overlay = dropdown.querySelector(".drop-overlay");

            if (overlay) overlay.style.display = "none";

            dropdown.classList.remove("hovered");
        });
    };

    const showActiveMenuAndHideOthers = (dropdown, menu) => {
        if (currentTimeout) clearTimeout(currentTimeout);

        currentTimeout = setTimeout(() => {
            hideMenusAndOverlays();

            showMenu(dropdown, menu);
        }, DELAY);
    };

    const hideActiveMenuAndOverlay = () => {
        if (currentTimeout) clearTimeout(currentTimeout);

        currentTimeout = setTimeout(() => hideMenusAndOverlays(), 100);
    };

    dropdowns.forEach((dropdown) => {
        const menu = dropdown.querySelector(".dropdown-menu");

        dropdown.addEventListener("mouseenter", () => showActiveMenuAndHideOthers(dropdown, menu));
        dropdown.addEventListener("mouseleave", () => hideActiveMenuAndOverlay(dropdown, menu));
        menu.addEventListener("click", () => hideMenusAndOverlays);
    });

    mainOverlay.addEventListener("mouseenter", hideMenusAndOverlays);
});
